<template>
  <ws-card :title="t('dashboard.recent-operations')" style="margin-top: 10px">
    <el-scrollbar v-if="recentOperatorsList.length">
      <div class="card-wrap">
        <div
          class="card-item"
          v-for="(item, index) in recentOperatorsList"
          :key="index"
        >
          <div class="card-item__img">
            <el-avatar
              v-if="!item.favicon"
              shape="square"
              src="https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png"
            />
            <el-avatar v-else shape="square" :src="item.favicon" />
          </div>
          <div class="card-content">
            <div class="flex-between">
              <div class="card-item__title">
                {{ item.createdBy }}
              </div>
              <div class="card-item__date">
                {{ $filters.getCurrentSystemTimeFormat(item.createdTime) }}
              </div>
            </div>
            <div class="card-item__module">
              {{ t('general.module') }}: {{ t(`menu.${item.module}`) }}
            </div>
            <div class="card-item__user">
              Operation Type: {{ item.operationType }}
            </div>
          </div>
        </div>
      </div>
    </el-scrollbar>
    <div class="empty-wrap" v-else>
      <img
        class="empty-img"
        alt="emptyImg"
        src="../../../../../static/img/empty-box.png"
      />
    </div>
  </ws-card>
</template>
<script setup>
import WsCard from '@/base-ui/card'
import { ref } from 'vue'
import { useStore } from 'vuex'
import i18n from '@/i18n'

const store = useStore()
const { t } = i18n.global

const recentOperatorsList = ref([])

const initRecentOperation = async () => {
  const data = await store.dispatch('dashboard/getRecentOperators')
  recentOperatorsList.value = data
}

initRecentOperation()
</script>
<style lang="scss" scoped>
.card-wrap {
  height: 320px;
  .card-item {
    display: flex;
    border-bottom: 1px solid #e4e7ed;
    margin-bottom: 10px;
    .card-content {
      width: 100%;
      .card-item {
        &__title {
          color: rgb(52, 152, 219);
          margin-bottom: 10px;
        }
        &__date {
          color: grey;
          font-size: 12px;
        }
        &__user {
          font-size: 14px;
          color: #34495e;
          margin: 5px 0;
        }
        &__module {
          font-size: 14px;
          color: #34495e;
        }
      }
    }
    .flex-between {
      display: flex;
      justify-content: space-between;
    }
    &__img {
      width: 70px;
    }
  }
}
.empty-wrap {
  height: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
