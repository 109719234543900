<template>
  <ws-card
    :title="t('dashboard.terminal-status-statistics')"
    style="margin-top: 10px"
  >
    <div class="total-terminal">Total Terminal: {{ totalTerminal }}</div>
    <page-echarts
      :optionsConfig="terminalStatisticOptions"
      :height="'300px'"
    ></page-echarts
  ></ws-card>
</template>
<script setup>
import WsCard from '@/base-ui/card'
import PageEcharts from '@/components/PageEcharts/page-echarts.vue'
import { ref } from 'vue'
import i18n from '@/i18n'
import { useStore } from 'vuex'

const { t } = i18n.global
const store = useStore()

const terminalCountArr = ref([])
const totalTerminal = ref()

const terminalStatisticOptions = {
  tooltip: {
    trigger: 'item'
  },
  legend: {
    orient: 'vertical',
    left: 'left'
  },
  series: [
    {
      type: 'pie',
      radius: '50%',
      data: terminalCountArr.value,
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    }
  ]
}

const initTerminalStatistics = async () => {
  const data = await store.dispatch('dashboard/getTerminalStatusStatistics')
  terminalCountArr.value.push({
    value: data.offlineTerminalCount,
    name: 'Offline Terminal'
  })
  terminalCountArr.value.push({
    value: data.activatedTerminalCount,
    name: 'Online Terminal'
  })
  totalTerminal.value = data.inventoryTerminalCount
}

initTerminalStatistics()
</script>
<style lang="scss" scoped>
.total-terminal {
  font-size: 12px;
  color: #5f5f5f;
  margin-bottom: 5px;
}
</style>
