<template>
  <div>
    <el-row :gutter="16">
      <el-col :span="8">
        <statistics-card
          faicon="fa fa-male"
          :title="t('dashboard.customer-title-info')"
          :count="navData?.customer"
          :rate="navData?.customerRatio"
        ></statistics-card>
      </el-col>
      <el-col :span="8">
        <statistics-card
          faicon="fa fa-maxcdn"
          :title="t('dashboard.merchant-title-info')"
          :count="navData?.merchant"
          :rate="navData?.merchantRatio"
        ></statistics-card>
      </el-col>
      <el-col :span="8">
        <statistics-card
          faicon="fa fa-mobile"
          :title="t('dashboard.terminal-title-info')"
          :count="navData?.terminal"
          :rate="navData?.terminalRatio"
        ></statistics-card>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <trans-panel></trans-panel>
      </el-col>
    </el-row>
    <el-row :gutter="16">
      <el-col :span="8"><terminal-chart></terminal-chart></el-col>
      <el-col :span="8"><recent-message></recent-message></el-col>
      <el-col :span="8"><recent-operator></recent-operator></el-col>
    </el-row>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
import StatisticsCard from '../common/component/statisticsCard/index.vue'
import TransPanel from '../common/component/transPanel/index.vue'
import RecentMessage from '../common/component/recentMessage/index.vue'
import RecentOperator from '../common/component/recentOperator/index.vue'
import TerminalChart from '../common/component/terminalChart/index.vue'
import momentTimeZone from 'moment-timezone'
import i18n from '@/i18n'

const { t } = i18n.global
const store = useStore()

const navData = ref({})

const getFirstMondayUTCDateTime = () => {
  const now = momentTimeZone()
  const dayOfWeek = now.day()
  // 如果不是周一，则计算到上一个周一的差值
  const daysToSubtract = dayOfWeek === 0 ? 6 : dayOfWeek - 1 // 特殊处理周日，因为我们要回到上周一
  const startOfWeek = now.subtract(daysToSubtract, 'days')
  console.log(startOfWeek)
  startOfWeek.hour(0).minute(0).second(0)
  const utcTime = startOfWeek.utc().format('YYYY-MM-DD HH:mm:ss')
  console.log(utcTime)
  return utcTime
}
getFirstMondayUTCDateTime()
const initNavData = async () => {
  const data = await store.dispatch('dashboard/getDashboardStatistic', {
    date: getFirstMondayUTCDateTime()
  })
  navData.value = data
}
initNavData()
</script>

<style lang="scss" scoped>
.card-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-title {
  color: #bcbcbc;
  font-size: 20px;
  margin-bottom: 8px;
  margin-left: -10px;
}
.card-content {
  .card-img-info {
    color: rgba(52, 152, 219);
    font-size: 36px;
    line-height: 47px;
    margin-right: 8px;
  }
  .card-count-info {
    font-size: 36px;
    line-height: 47px;
    font-weight: bold;
  }
}

.card-bottom-compare {
  color: #bcbcbc;
  margin-top: 10px;
  margin-left: -30px;
  font-size: 15px;
  .card-icon {
    color: rgb(26, 187, 156);
    margin-right: 8px;
  }
}

.carousel-wrapper {
  margin-top: 15px;
}

.el-carousel__item div {
  color: #475669;
  opacity: 0.8;

  margin: 0;
  text-align: center;
}
</style>
