<template>
  <el-card class="trans-panel">
    <template #header>
      <div class="card-header">
        <div class="title">{{ t('dashboard.terminal-statistics') }}</div>
        <el-date-picker
          v-model="chosenDate"
          format="YYYY-MM-DD"
          valueFormat="YYYY-MM-DD"
          type="daterange"
          :start-placeholder="$t('general.start-time')"
          :end-placeholder="$t('general.end-time')"
        >
        </el-date-picker>
      </div>
    </template>
    <div class="trans-panel__content">
      <div class="trans-chart">
        <page-echarts
          ref="transChart"
          :isShowToolBox="false"
          :optionsConfig="tranStatisticOptions"
          :height="'320px'"
        ></page-echarts>
      </div>
    </div>
  </el-card>
</template>
<script setup>
import PageEcharts from '@/components/PageEcharts/page-echarts.vue'
import { ref, defineProps, watch } from 'vue'
import i18n from '@/i18n'
import { useStore } from 'vuex'
import {
  getStandardDate,
  getStandardDateBeforeWeek,
  convertDateStringToTimezoneOnlyDate
} from '@/utils/util.js'

defineProps({
  title: {
    type: String,
    default: ''
  }
})

const store = useStore()
const { t } = i18n.global
const chosenDate = ref([])
const transChart = ref('')
const dayArr = ref([])
const terminalStatistic = ref([])

const tranStatisticOptions = {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
      label: {
        backgroundColor: '#6a7985'
      }
    }
  },
  legend: {
    data: [t('param.terminal')]
  },
  grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true
  },
  xAxis: [
    {
      type: 'category',
      boundaryGap: false,
      data: dayArr.value
    }
  ],
  yAxis: [
    {
      type: 'value'
    }
  ],
  series: [
    {
      name: t('param.terminal'),
      type: 'line',
      stack: 'Total',
      areaStyle: {
        color: 'rgb(154,188,195)',
        opacity: 0.75
      },
      label: {
        show: true,
        position: 'top'
      },
      smooth: true,
      emphasis: {
        focus: 'series'
      },
      itemStyle: {
        color: 'rgb(154,188,195)',
        lineStyle: {
          color: 'rgb(154,188,195)'
        }
      },
      data: terminalStatistic.value
    }
  ]
}

const getChartData = async (startDateTime, endDateTime) => {
  // 转化数据
  const formatStartDateTime = convertDateStringToTimezoneOnlyDate(
    startDateTime,
    'startDateTime'
  )
  const formatEndDateTime = convertDateStringToTimezoneOnlyDate(
    endDateTime,
    'endDateTime'
  )
  const data = await store.dispatch('dashboard/getTerminalDailyStatistics', {
    startDateTime: formatStartDateTime,
    endDateTime: formatEndDateTime,
    showStartDateTime: startDateTime,
    showEndDateTime: endDateTime
  })
  dayArr.value.length = 0
  terminalStatistic.value.length = 0
  data.forEach((item) => {
    terminalStatistic.value.push(item?.num || 0)
    dayArr.value.push(item.date)
  })
}

watch(
  () => chosenDate.value,
  (newVal) => {
    if (newVal == null || newVal.length === 0) {
      // 将清空后的值为默认的一周 清空按钮的事件处理
      chosenDate.value = [getStandardDateBeforeWeek(), getStandardDate()]
    }
    if (newVal && newVal.length && newVal.length !== 0) {
      getChartData(newVal[0], newVal[1])
    }
  },
  { immediate: true, deep: true }
)
</script>
<style lang="scss" scoped>
.trans-panel {
  width: 100%;
  margin-top: 10px;
  :deep(.el-card__header) {
    padding: 10px;
  }
  .card-header {
    display: flex;
    justify-content: space-between;
    .title {
      line-height: 40px;
    }
  }
  &__content {
    display: flex;
    color: #73879c;
    .trans-chart {
      width: 100%;
    }
    .progress-wrap {
      width: 25%;
      .progress-title {
        margin-bottom: 25px;
        font-size: 20px;
      }
      .progress-item {
        margin-bottom: 20px;
        &__title {
          margin-bottom: 15px;
        }
      }
    }
  }
}
</style>
