<template>
  <div class="pie-echarts">
    <base-echarts :options="options" :height="height" :width="width">
    </base-echarts>
  </div>
</template>

<script setup>
import BaseEcharts from '@/base-ui/echart'
import { computed, defineProps } from 'vue'
import { defaultFeatureOptions } from './toolBox'

const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  // 当不是基础的Tool Options可通过这个进行配置扩展
  toolFeatureOptions: {
    type: Object,
    default: () => ({})
  },
  width: {
    type: String,
    default: '100%'
  },
  height: {
    type: String,
    default: '240px'
  },
  // 是否展示工具栏
  isShowToolBox: {
    type: Boolean,
    default: true
  },
  // 图标特有的配置选项
  optionsConfig: {
    type: Object,
    default: () => ({})
  }
})

const options = computed(() => {
  return {
    title: {
      text: props.title,
      left: 'left'
    },
    tooltip: {
      trigger: 'item'
    },
    toolbox: {
      show: props.isShowToolBox,
      feature: {
        ...defaultFeatureOptions,
        ...props.toolFeatureOptions
      }
    },
    legend: {
      orient: 'vertical',
      left: 'right',
      top: 'bottom'
    },
    series: [],
    ...props.optionsConfig
  }
})
</script>

<style lang="scss" scoped></style>
