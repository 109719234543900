import i18n from '@/i18n'
const { t } = i18n.global

export const defaultFeatureOptions = {
  dataView: {
    readOnly: true,
    title: t('general.data-view-echarts-title'),
    lang: [t('general.data-view-echarts-title'), t('general.close')]
  },

  saveAsImage: {
    title: t('general.save-as-image-echart-tool')
  }
}
